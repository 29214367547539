import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
window.TestimonialSlider = (paginationClass) => {
    return {
        swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    loop: true,
                    spaceBetween: 100,
                    a11y: true,
                    pagination: {
                        el: paginationClass,
                        clickable: true,
                        type: 'bullets',
                    },
                    effect: 'fade',
                    slidesPerView: 1,
                    autoplay: {
                        delay: 7000,
                    },
                });
            }, 0);
        },
    };
};
